
    import VuePrismEditor from "vue-prism-editor";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            VuePrismEditor,
        },
    })

    // *****************************************************************************************************************
    export default class DevAws extends Vue {
        // Data function
        public data() {
            return {
                systemsIconPathAndName:
                    require("@/assets/images/icon_systems.png"),

                awsBucketPolicies:
                    '{' + '\n' +
                    '    "Version": "2012-10-17",' + '\n' +
                    '    "Statement": [' + '\n' +
                    '        {' + '\n' +
                    '            "Sid": "Grant users full access",' + '\n' +
                    '            "Effect": "Allow",' + '\n' +
                    '            "Principal": {' + '\n' +
                    '                "AWS": [' + '\n' +
                    '                "arn:aws:iam::<user name 1>:root",' + '\n' +
                    '                "arn:aws:iam::<user name 2>:root"' + '\n' +
                    '            ]' + '\n' +
                    '        },' + '\n' +
                    '        "Action": [' + '\n' +
                    '            "s3:DeleteObject",' + '\n' +
                    '            "s3:ListBucket",' + '\n' +
                    '            "s3:GetObject",' + '\n' +
                    '            "s3:GetObjectVersion",' + '\n' +
                    '            "s3:GetBucketLocation",' + '\n' +
                    '            "s3:PutObject",' + '\n' +
                    '            "s3:PutObjectAcl"' + '\n' +
                    '        ],' + '\n' +
                    '        "Resource": [' + '\n' +
                    '            "arn:aws:s3:::<bucket name>",' + '\n' +
                    '            "arn:aws:s3:::<bucket name>/*"' + '\n' +
                    '        ]' + '\n' +
                    '    ]' + '\n' +
                    '}' + '\n',

                cloudFrontAccess:
                    '{' + '\n' +
                    '  "Version": "2012-10-17",' + '\n' +
                    '  "Statement": [' + '\n' +
                    '      {' + '\n' +
                    '      "Sid": "Grant a CloudFront Origin Identity access to support private content",' + '\n' +
                    '      "Effect": "Allow",' + '\n' +
                    '      "Principal": {' + '\n' +
                    '          "CanonicalUser": "<64-digit-alphanumeric-value>"' + '\n' +
                    '      },' + '\n' +
                    '      "Action": [' + '\n' +
                    '          "s3:ListBucket",' + '\n' +
                    '          "s3:GetObject",' + '\n' +
                    '          "s3:GetObjectVersion",' + '\n' +
                    '          "s3:GetBucketLocation"' + '\n' +
                    '      ],' + '\n' +
                    '      "Resource": [' + '\n' +
                    '          "arn:aws:s3:::<bucket name>",' + '\n' +
                    '         "arn:aws:s3:::<bucket name>/*"' + '\n' +
                    '      ]' + '\n' +
                    '    }' + '\n' +
                    '  ]' + '\n' +
                    '}' + '\n',

                publicReadOnlyAccess:
                    '{' + '\n' +
                    '  "Version": "2012-10-17",' + '\n' +
                    '  "Statement": [' + '\n' +
                    '      {' + '\n' +
                    '      "Sid": "Grant PublicReadGetObject",' + '\n' +
                    '      "Effect": "Allow",' + '\n' +
                    '      "Principal": "*",' + '\n' +
                    '      "Action": [' + '\n' +
                    '          "s3:GetObject"' + '\n' +
                    '      ],' + '\n' +
                    '      "Resource": [' + '\n' +
                    '          "arn:aws:s3:::unusualreality.com",' + '\n' +
                    '          "arn:aws:s3:::unusualreality.com/*"' + '\n' +
                    '      ]' + '\n' +
                    '    }' + '\n' +
                    '  ]' + '\n' +
                    '}' + '\n',

                multiplePolicies:
                    '{' + '\n' +
                    '  "Version": "2012-10-17",' + '\n' +
                    '  "Statement": [' + '\n' +
                    '    {' + '\n' +
                    '      "Sid": "Grant users full access",' + '\n' +
                    '      "Effect": "Allow",' + '\n' +
                    '      "Principal": {' + '\n' +
                    '        "AWS": [' + '\n' +
                    '          "arn:aws:iam::<user name 1>:root",' + '\n' +
                    '          "arn:aws:iam::<user name 2>:root"' + '\n' +
                    '        ]' + '\n' +
                    '      },' + '\n' +
                    '      "Action": [' + '\n' +
                    '        "s3:DeleteObject",' + '\n' +
                    '        "s3:ListBucket",' + '\n' +
                    '        "s3:GetObject",' + '\n' +
                    '        "s3:GetObjectVersion",' + '\n' +
                    '        "s3:GetBucketLocation",' + '\n' +
                    '        "s3:PutObject",' + '\n' +
                    '        "s3:PutObjectAcl"' + '\n' +
                    '      ],' + '\n' +
                    '      "Resource": [' + '\n' +
                    '        "arn:aws:s3:::<bucket name>",' + '\n' +
                    '        "arn:aws:s3:::<bucket name>/*"' + '\n' +
                    '      ]' + '\n' +
                    '    },' + '\n' +
                    '    {' + '\n' +
                    '      "Sid": "Grant a CloudFront Origin Identity access to support private content",' + '\n' +
                    '      "Effect": "Allow",' + '\n' +
                    '      "Principal": {' + '\n' +
                    '        "CanonicalUser": "<64-digit-alphanumeric-value>"' + '\n' +
                    '      },' + '\n' +
                    '      "Action": [' + '\n' +
                    '        "s3:ListBucket",' + '\n' +
                    '        "s3:GetObject",' + '\n' +
                    '        "s3:GetObjectVersion",' + '\n' +
                    '        "s3:GetBucketLocation"' + '\n' +
                    '      ],' + '\n' +
                    '      "Resource": [' + '\n' +
                    '        "arn:aws:s3:::<bucket name>",' + '\n' +
                    '        "arn:aws:s3:::<bucket name>/*"' + '\n' +
                    '      ]' + '\n' +
                    '    },' + '\n' +
                    '    {' + '\n' +
                    '      "Sid": "Grant PublicReadGetObject",' + '\n' +
                    '      "Effect": "Allow",' + '\n' +
                    '      "Principal": "*",' + '\n' +
                    '      "Action": [' + '\n' +
                    '      "s3:GetObject"' + '\n' +
                    '    ],' + '\n' +
                    '    "Resource": [' + '\n' +
                    '        "arn:aws:s3:::unusualreality.com",' + '\n' +
                    '        "arn:aws:s3:::unusualreality.com/*"' + '\n' +
                    '      ]' + '\n' +
                    '    }' + '\n' +
                    '  ]' + '\n' +
                    '}' + '\n',

                corsPolicies:
                    '{' + '\n' +
                    '    <CORSConfiguration>' + '\n' +
                    '        <CORSRule>' + '\n' +
                    '            <AllowedOrigin>https://unusualreality.com</AllowedOrigin>' + '\n' +
                    '            <AllowedOrigin>http://unusualreality.com</AllowedOrigin>' + '\n' +
                    '            <AllowedMethod>GET</AllowedMethod>' + '\n' +
                    '            <AllowedMethod>HEAD</AllowedMethod>' + '\n' +
                    '            <MaxAgeSeconds>3000</MaxAgeSeconds>' + '\n' +
                    '            <AllowedHeader>Authorizatin</AllowedHeader>' + '\n' +
                    '        </CORSRule>' + '\n' +
                    '    </CORSConfiguration>' + '\n' +
                    '}' + '\n',
            };
        }
     }
